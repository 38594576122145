import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Welcome from '../auth/Welcome';
import $ from 'jquery';
import { SetCookie } from '../../utils/MojaveLib';

function LoginLayout(){

    const [defaultLang, setDefaultLang] = useState('en');
    const {t, i18n} = useTranslation('translation');

    useEffect(() => {
        // $('body').attr('style', 'background-image: url(assets/images/login_bg.jpeg);background-size: auto 100%;background-repeat:no-repeat');

        changeLanguage('en');
    },[i18n]);

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);

        setDefaultLang(lang);

        SetCookie('acargo-language', lang);
    }

    return(
        <div id='loginMainCon'>
            <Welcome />
        </div>
    );
}

export default LoginLayout;
