import React from "react";

const Informational = React.lazy(() => import('../components/dashboard/Informational'));
const Analytic = React.lazy(() => import('../components/dashboard/Analytics'));
const Strategic = React.lazy(() => import('../components/dashboard/Strategic'));

const Shipment = React.lazy(() => import('../components/operations/shipments/Shipment'));
const Customer = React.lazy(() => import('../components/operations/customers/Customer'));
const Transaction = React.lazy(() => import('../components/operations/transactions/Transactions'));
const Mission = React.lazy(() => import('../components/operations/missions/Mission'));
const Manifest = React.lazy(() => import('../components/operations/manifest/Manifest'));
const CustomerService = React.lazy(() => import('../components/operations/customerService/CustomerService'));
const SupplierPayment = React.lazy(() => import('../components/operations/supplierPayment/SupplierPayment'));
const Packages = React.lazy(() => import('../components/operations/packages/Package'));

const GeneralSettings = React.lazy(() => import('../components/settings/General'));
const PackageSettings = React.lazy(() => import('../components/settings/Packages'));
const AccessControl = React.lazy(() => import('../components/settings/AccessControl'));
const Location = React.lazy(() => import('../components/settings/Location'));
const Currency = React.lazy(() => import('../components/settings/Currency'));
const ShipRate = React.lazy(() => import('../components/settings/ShipmentRates'));
const MyProfile = React.lazy(() => import('../components/auth/MyProfile'));

const Profile = React.lazy(() => import('../components/clients/CustomersProfile'));
const Address = React.lazy(() => import('../components/clients/CustomersAddress'));
const MyTransaction = React.lazy(() => import('../components/clients/MyTransaction'));
const MyShipment = React.lazy(() => import('../components/clients/Shipment'));
const postalAddress = React.lazy(() => import('../components/clients/ForeignAddress'));

const ShipmentReport = React.lazy(() => import('../components/reports/ShipmentReport'));
const MissionReport = React.lazy(() => import('../components/reports/MissionReport'));
const TransactionReport = React.lazy(() => import('../components/reports/TransactionReport'));
const CustomerReport = React.lazy(() => import('../components/reports/CustomerReport'));
const BranchReport = React.lazy(() => import('../components/reports/BranchReport'));
const DriverReport = React.lazy(() => import('../components/reports/DriverReport'));

const Welcome = React.lazy(() => import('../components/auth/Welcome'));

const routes = [
    { path: "/dashboard/informational", name: "Informational Dashboard", component: Informational },
    { path: "/dashboard/analytic", name: "Analytic Dashboard", component: Analytic },
    { path: "/dashboard/strategic", name: "Strategic Dashboard", component: Strategic },

    { path: "/operation/shipment", name: "Shipment", component: Shipment },
    { path: "/operation/customer", name: "Customer", component: Customer },
    { path: "/operation/transaction", name: "Transactions", component: Transaction },
    { path: "/operation/mission", name: "Missions", component: Mission },
    { path: "/operation/manifest", name: "Manifests", component: Manifest },
    { path: "/operation/supplierPayment", name: "Supplier Payment", component: SupplierPayment },
    { path: "/operation/customerService", name: "Customer Service", component: CustomerService },
    { path: "/operation/package", name: "Package", component: Packages },

    { path: "/setting/general", name: "General Settings", component: GeneralSettings },
    { path: "/setting/package", name: "Package Settings", component: PackageSettings },
    { path: "/setting/access_control", name: "Access Control", component: AccessControl },
    { path: "/setting/location", name: "Location", component: Location },
    { path: "/setting/currency", name: "Currency", component: Currency },
    { path: "/setting/rate", name: "Shipping Rates", component: ShipRate },
    { path: "/setting/myProfile", name: "My Profile", component: MyProfile },

    { path: "/client/profile", name: "Customers", component: Profile},
    { path: "/client/shipment", name: "Shipments", component: MyShipment},
    { path: "/client/address", name: "Address", component: Address},
    { path: "/client/postalAddress", name: "Postal Address", component: postalAddress},
    { path: "/client/transaction", name: "Transactions", component: MyTransaction},

    {path: "/report/shipment", name: "Shipment Report", component: ShipmentReport},
    {path: "/report/mission", name: "Mission Report", component: MissionReport},
    {path: "/report/transaction", name: "Transaction Report", component: TransactionReport},
    {path: "/report/customer", name: "Customer Report", component: CustomerReport},
    {path: "/report/branch", name: "Branch Report", component: BranchReport},
    {path: "/report/driver", name: "Driver Report", component: DriverReport},

    {path: "/welcome", name: "Welcome", component: Welcome},
];

export default routes;