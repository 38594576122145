import React, { useEffect } from "react";
import ReactDOM from 'react-dom';
import logo from '../../utils/assets/images/logo.png';
import cargoImg from '../../utils/assets/images/delivery-rx-tranfarency.png';
import $ from 'jquery';
import Login from "./Login";
import { useTranslation } from "react-i18next"; //first import
import { GetCookie, SetCookie } from "../../utils/MojaveLib";

function Welcome(){

    const {t, i18n} = useTranslation('translation'); //initialize

    useEffect(() => {
        $('body').removeAttr('style').attr('background-color:#FFFFFF');

    }, [i18n]);

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);

        SetCookie('acargo-language', lang);
    }

    const showLogin = () => ReactDOM.render(<Login changeLanguage={changeLanguage} />, document.getElementById('loginMainCon'));

    return(
        <div className="row">
            <div className="col-xs-12 col-sm-10 col-md-9 col-lg-8" style={{padding: '20px 40px', margin:'0px auto'}}>
                <div className="d-flex justify-content-between">
                    <img src={logo} alt="logo" style={{height: '40px'}} />
                    <div className="d-flex">
                        <select className="form-select form-select-sm" name="" id=""
                            value={GetCookie('acargo-language')} onChange={(e) => changeLanguage(e.target.value)} 
                            style={{height: '32px', width: '80px', marginRight:'20px'}}>
                            <option value={'en'}>{t("menu.langen")} </option>
                            <option value={'zh'}>{t("menu.langzh")} </option>
                        </select>
                        
                        <a href="#/" onClick={showLogin} style={{float:'right',fontSize:'18px'}}>{t("frontPage.login")} </a>{/** Entry */}
                    </div>
                </div>

                <div className="row align-items-center" style={{height: '80vh'}}>
                    <form className="form" >
                        <div className="row centent">
                            <div className="col-sm-12 col-lg-8">
                                <div className="text-left">
                                    <div style={{fontSize:'50px', fontWeight:'600'}}>Adinkra {t("frontPage.cargo")} {t("frontPage.movers")} </div>
                                    <div style={{fontSize:'30px'}}>{t("frontPage.motto")}</div>
            
                                    <div className="">
                                        <input type="text" name="code" className=""
                                            placeholder={t("frontPage.track")} 
                                            style={{padding: '10px 15px', backgroundColor:'white', border:'1px solid gray', 
                                            borderRadius:'30px', width:'60%'}} />
                                        <button className="btn btn-primary Track-button ml-4" style={{borderRadius:'30px'}}>{t("frontPage.btnTrack")}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-4">
                                <img src={cargoImg} alt="Full Page Photo"  style={{width: '600px'}} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Welcome;
