import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import logo from '../../utils/assets/images/logo.png'
import Login from './Login';
import { DConnect, DecryptData, GetCookie, MakeId, PostOptions, showAlert } from '../../utils/MojaveLib';
import Dto, { branch } from '../../utils/serializers';
import _ from 'lodash';
import { useTranslation } from "react-i18next";

function Register({changeLanguage}){

    const {t, i18n} = useTranslation('translation');

    const [formItems, setFormItems] = useState(Dto.clients);
    const [branches, setBranches] = useState([]);
    const [cities, setCities] = useState({});

    useEffect(() => {

        getInitData();
    },[]);

    const getInitData = () => {

        // let message = `Dear #firstName, you are officially registered with Adinkra with a unique code: #code.`,
        // arr = message.split(/[^a-z\#]+/gi).filter(Boolean).filter(res => res.startsWith('#'))
        // .map(res => res.replaceAll('#',''));

        // alert(arr)

        DConnect().get(`branch`).then(res => {
            let branches = DecryptData(res.data);

            if(branches.length > 0){
                setBranches(branches);
            }else{
                setBranches([]);
            }
        }).catch(error => alert(error.message));

        DConnect().get(`city`).then(res => {
            let cities = DecryptData(res.data);

            if(cities.length > 0){
                setCities(_.groupBy(cities,'id'));
            }else{
                setCities({});
            }
        }).catch(error => alert(error.message));
    }

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        setFormItems(prevProps => ({...prevProps, [name]:value}));
    }

    const handleTermChange = (e) => {

        const {name, value} = e.target;

        setFormItems(prevProps => ({...prevProps, [name]:e.target.checked}));
    }

    const submitRegister = async (e) => {
        e.preventDefault();

        let formData = {...formItems},
            curBranch = branches.filter(branch => branch.id == formData.branchId),
            curCity = cities[`${curBranch[0].cityId}`],
            clientCode = ``, clientExist = [];

        formData.city = curCity[0].code;

        do{
            clientCode = `${curCity[0].code}${MakeId(4)}`;
            clientExist = await DConnect().get(`client/?code=${clientCode}`);
        }while(clientExist.lenth > 0);

        formData.code = clientCode;

        axios(PostOptions("POST", formData, `client`)).then(res => {
            let resdata = DecryptData(res.data);

            showAlert({ title: 'Registration', message: `Registration successfully...`, btnText: 'OK' })
            .then(ress => window.location.reload(true));
        }).catch(error => {
            showAlert({ title: 'Registration Error', message: `Your email and/or mobile number might already be in the system, please check and try again...`, btnText: 'OK' })
            .then(ress => window.location.reload(true));
        });
    }

    const showLogin = () => ReactDOM.render(<Login changeLanguage={changeLanguage} />, document.getElementById('loginMainCon'));

    return(
        <div className="row" style={{backgroundImage:'url(assets/images/login_bg.jpeg)', backgroundRepeat:'no-repeat', backgroundSize:'100% auto'}}>	
            <div className="col-12">
                <div className="row justify-content-end g-0">
                    <div className="col-xl-3 col-lg-4 col-md-5 col-12 " style={{height: '100vh'}}>
                        <div className="bg-white shadow-lg" style={{height: '100%', position:'relative'}}>
                            <div className="content-top-agile p-20 mb-4 align-self-center">
                                <img src={logo} alt='Adinkra logo' style={{width: '200px'}} className='mb-4' />
                                <select className="form-select form-select-sm" name="" id=""
                                    value={GetCookie('acargo-language')} onChange={(e) => changeLanguage(e.target.value)} 
                                    style={{height: '32px', width: '80px', margin:'20px auto'}}>
                                    <option value={'en'}>{t("menu.langen")} </option>
                                    <option value={'zh'}>{t("menu.langzh")} </option>
                                </select>
                                <p className="mb-0">{t("login.create")}</p>
                            </div>

                            <div className="p-40" style={{bottom:'30px', position:'absolute'}}>
                            <form id="fromRegister" onSubmit={submitRegister}>
									<div class="form-group">
										<div class="input-group mb-3">
											<span class="input-group-text bg-transparent"><i class="ti-user"></i></span>
											<input type="text" class="form-control ps-15 bg-transparent" name='firstName' id='firstName'
                                                placeholder={t("login.fname")} value={formItems.firstName} onChange={handleChange} required />
										</div>
									</div>
                                    <div class="form-group">
										<div class="input-group mb-3">
											<span class="input-group-text bg-transparent"><i class="ti-user"></i></span>
											<input type="text" class="form-control ps-15 bg-transparent" name='lastName' id='lastName'
                                                placeholder={t("login.lname")} value={formItems.lastName} onChange={handleChange} required />
										</div>
									</div>
									<div class="form-group">
										<div class="input-group mb-3">
											<span class="input-group-text bg-transparent"><i class="ti-email"></i></span>
											<input type="email" class="form-control ps-15 bg-transparent" name='email' id='email'
                                                placeholder={t("login.email")} value={formItems.email} onChange={handleChange} required />
										</div>
									</div>
									<div class="form-group">
										<div class="input-group mb-3">
											<span class="input-group-text bg-transparent"><i class="ti-lock"></i></span>
											<input type="password" class="form-control ps-15 bg-transparent" name='password' id='password'
                                                placeholder={t("login.password")} value={formItems.password} onChange={handleChange} required />
										</div>
									</div>
									<div class="form-group">
										<div class="input-group mb-3">
											<span class="input-group-text bg-transparent"><i class="ti-credit-card"></i></span>
											<input type="text" class="form-control ps-15 bg-transparent" name='nationalId' id='nationalId'
                                                placeholder={t("login.id")} value={formItems.nationalId} onChange={handleChange} required />
										</div>
									</div>
                                    <div class="form-group">
										<div class="input-group mb-3">
											<span class="input-group-text bg-transparent"><i class="ti-mobile"></i></span>
											<input type="tel" class="form-control ps-15 bg-transparent" id='responsibleMobile' name='responsibleMobile'
                                                value={formItems.responsibleMobile} onChange={handleChange} placeholder={t("login.tel")} required />
										</div>
									</div>

                                    <div class="form-group">
										<div class="input-group mb-3">
											<span class="input-group-text bg-transparent"><i class="ti-map"></i></span>
                                            <select class="form-control ps-15 bg-transparent" name='branchId' id='branchId' 
                                                value={formItems.branchId} onChange={handleChange} required>
                                                <option value="">{t("login.select_b")}</option>
                                                {branches.map(branch => 
                                                <option value={branch.id}>{branch.name}</option>)}
                                            </select>
										</div>
									</div>
                                    <div class="row">
										<div class="col-12">
										  <div class="checkbox">
											<input type="checkbox" id="terms" name='terms' required
                                                value={true} onChange={handleTermChange} />
											<label for="terms">{t("login.agree")} <a href="#" class="text-warning"><b>{t("login.terms")}</b></a></label>
										  </div>
										</div>
										
										<div class="col-12 text-center mt-4">
										  <button type="submit" class="btn btn-info margin-top-10">{t("login.btnReg")}</button>
										</div>
										
                                    </div>
								</form>				
								<div class="text-center">
									<p class="mt-15 mb-0">{t("login.account")}?<a href="#" onClick={showLogin} class="text-danger ms-5"> {t("login.btnLog")}</a></p>
								</div>
                            </div>		
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register
