import React from 'react';
import ReactDOM from 'react-dom';
import logo from '../../utils/assets/images/logo.png';
import Login from './Login';
import { useTranslation } from "react-i18next";
import { GetCookie } from '../../utils/MojaveLib';

function UserPass({changeLanguage}){

     const {t, i18n} = useTranslation('translation');

    const showLogin = () => ReactDOM.render(<Login changeLanguage={changeLanguage} />, document.getElementById('loginMainCon'));

    return(
        <div className="row">	
            <div className="col-12">
                <div className="row justify-content-end g-0">
                    <div className="col-xl-3 col-lg-4 col-md-5 col-12 " style={{height: '100vh'}}>
                        <div className="bg-white shadow-lg" style={{height: '100%', position:'relative'}}>
                            {/* <div className='align-items-center'> */}
                            <div className="content-top-agile p-20 mb-4 align-self-center">
                                <img src={logo} alt='Adinkra logo' style={{width: '200px'}} className='mb-4' />
                                <select className="form-select form-select-sm" name="" id=""
                                    value={GetCookie('acargo-language')} onChange={(e) => changeLanguage(e.target.value)} 
                                    style={{height: '32px', width: '80px', margin:'20px auto'}}>
                                    <option value={'en'}>{t("menu.langen")} </option>
                                    <option value={'zh'}>{t("menu.langzh")} </option>
                                </select>
                                <p className="mb-0">{t("login.remember")}</p>							
                            </div>

                            <div className="p-40" style={{bottom:'30px', position:'absolute'}}>
                                <form action="index.html" method="post">
									<div class="form-group">
										<div class="input-group mb-3">
											<span class="input-group-text bg-transparent"><i class="ti-email"></i></span>
											<input type="email" class="form-control ps-15 bg-transparent" placeholder={t("login.email")} />
										</div>
									</div>
									  <div class="row">
										<div class="col-12 text-center">
										  <button type="submit" class="btn btn-info margin-top-10">Reset</button>
										</div>
										
									  </div>
								</form>	
                                <div class="text-center">
									<p class="mt-15 mb-0">{t("login.account")}<a href="#" onClick={showLogin} class="text-danger ms-5"> Log In</a></p>
								</div>	
                            </div>		
                        </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserPass
