import React from 'react';

const Dto = () => {}

const login = {
    'email':'', 
    'password':'',
    'rememberMe': false
};

const packages = {
    'id':0, 
    'name':'',
    'cost': 0.0,
    'createdBy':0,
    'updatedBy':0
};

const roles = {
    'id':0,
    'roleName':'',
    'roleLevel':0,
    'features':'',
    'status':1,
    'createdBy':0,
    'updatedBy':0
};

const users = {
    'id':0,
    'roleId':0,
    'username':'',
    'email':'',
    'status':1,
    'mobile':'',
    'phone':'',
    'password':'',
    'rememberToken':'',
    'mobileVerified':false,
    'emailVerified':false,
    'lastLogin':new Date(),
    'lastPassChange':new Date(),
    'avatar':0,
    'firstName':'',
    'lastName':'',
    'createdBy':0,
    'updatedBy':0
};

const clients = {
    'id': 0,
    'code': '',
    'firstName': '',
    'lastName': '',
    'email': '',
    'businessName':'',
    'responsibleName': '',
    'responsibleMobile': '',
    'followUpName': '',
    'followUpMobile': '',
    'followUpCountryCode': '',
    'nationalId': '',
    'idType':0,
    'defaultAddress': 0,
    'branchId': 0,
    'isArchived': false,
    'createdBy': 0,
    'updatedBy': 0
};

const country = {
    'id':0,
    'name':'',
    'code':'',
    'iso3':'',
    'iso2':'',
    'phonecode':'',
    'capital':'',
    'currency':'',
    'currencySymbol':'',
    'timezones':'',
    'region':'',
    'subRegion':'',
    'lattitude':0,
    'longitude':0,
    'createdBy':0,
    'updatedBy':0
};

const region = {
    'id':0,
    'name':'',
    'code':'',
    'countryId':0,
    'longitude':0,
    'lattitude':0,
    'createdBy':0,
    'updatedBy':0
};

const city = {
    'id':0,
    'name':'',
    'code':'',
    'jsonId':0,
    'regionId':0,
    'longitude':0,
    'lattitude':0,
    'createdBy':0,
    'updatedBy':0
};

const areas = {
    'id':0,
    'name':'',
    'code':'',
    'cityId':0,
    'lattitude':0,
    'longitude':0,
    'createdBy':0,
    'updatedBy':0
};

const branch = {
    'id': 0,
    'name': '',
    'code': '',
    'cityId': 0,
    'email': '',
    'address': '',
    'latitude':0.0,
    'longitude':0.0,
    'isArchived': false,
    'createdBy': 0,
    'updatedBy': 0
};

const dropdown = {
    'id': 0,
    'pretype': '',
    'prename': '',
    'prevalue': '',
    'prextra': 0.0,
    'createdBy': 0,
    'updatedBy': 0
};

const message = {
    'id': 0,
    'message': '',
    'messageType': 0,
    'messageArea': 0,
    'createdBy': 0,
    'updatedBy': 0
};

const placeholder = {
    'id': 0,
    'schema': '',
    'messageArea': 0,
    'fieldname': 0,
    'createdBy': 0,
    'updatedBy': 0
};

const currency = {
    'id': 0,
    'name': '',
    'symbol': '',
    'exchangeRate': 0.0,
    'default': false,
    'code': '',
    'status': 1,
    'createdBy': 0,
    'updatedBy': 0
};

const cost = {
    'id': 0,
    'shipType': 0,
    'packageType': 0,
    'costType': 0,
    'cbm': 0,
    'weight': 0,
    'pickupCost': 0,
    'repackageCost': 0,
    'shipmentCost': 0,
    'returnCost': 0,
    'tax': 0,
    'insurance': 0,
    'fromCountryId': 0,
    'fromRegionId': 0,
    'fromCityId': 0,
    'toCountryId': 0,
    'toRegionId': 0,
    'toAreaId': 0,
    'createdBy': 0,
    'updatedBy': 0
}

const address = {
    'id':0,
    'clientCode': '',
    'countryId': 0,
    'regionId': 0,
    'cityId': 0,
    'areaId': 0,
    'streetAddress': '',
    'clientLat': 0.0,
    'clientLng': 0.0,
    'clientUrl': '',
    'isDefault': false,
    'isArchived': false,
    'createdBy': 0,
    'updatedBy': 0,
}

const shipment = {
    'id': 0,
    'code': '',
    'statusId': 0,
    'shipmentType': 1,
    'subType': 0,
    'branchId': 0,
    'shipmentDate': null,
    'shippingLine': '',
    'containerNo': '',
    'containerType': 0,
    'landingPort': '',
    'loadingPort': '',
    'weight': 0.0,
    'deliveryEta': null,
    'isDelayed': false,
    'delayedReason': 0,
    'missionId': 0,
    'fromCountryId': 0,
    'toCountryId': 0,
    'createdBy': 0,
    'updatedBy': 0,
}

const shipmentPackage = {
    'id': 0,
    'batchNo': 0,
    'warehouseId': 0,
    'packageType': 1,
    'packageCode': '',
    'shipmentCode': '',
    'clientCode': '',
    'clientAddress': 0,
    'weight': 0.0,
    'volume': 0.0,
    'cbm': 0.0,
    'length': 0.0,
    'width': 0.0,
    'height': 0.0,
    'qty': 0,
    'shipmentAmount':0.0,
    'deliveryCharge':0.0,
    'deliveryType': 0,
    'repackageCharge':0.0,
    'description': '',
    'barcode': '',
    'supplierCode': '',
    'paymentType': 0,
    'paymentAmount':0,
    'statusId': 0,
    'otp': '',
    'pickedBy':'',
    'pickedMobile':'',
    'createdBy': 0,
    'updatedBy': 0,
}

const accounts = {
    'id': 0,
    'glCode': '',
    'glName': '',
    'level1Code': '',
    'level2Code': '',
    'createdBy': 0,
    'updatedBy': 0
}

const accountLevel1 = {
    'id': 0,
    'level1Code': '',
    'level1Name': '',
    'createdBy': 0,
    'updatedBy': 0
}

const accountLevel2 = {
    'id': 0,
    'level1Code': '',
    'level2Code': '',
    'level2Name': '',
    'createdBy': 0,
    'updatedBy': 0
}

const glConfig = {
    'id': 0,
    'glType': 0,
    'glCode': '',
    'level1Code': '',
    'level2Code': '',
    'createdBy': 0,
    'updatedBy': 0
}

const glBalances = {
    'id': 0,
    'glCode': '',
    'balance': 0.0,
    'level1Code': '',
    'level2Code': ''
}

const clientBalance = {
    'id': 0,
    'clientCode': 0,
    'clientBalance': 0.0,
    'lastAmount': 0.0,
    'lastAmountDate': null,
    'lastAmountPaid': 0.0,
    'lastPaidDate': null,
    'createdBy': 0,
    'updatedBy': 0
}

const journals = {
    'id': 0,
    'clientCode': '',
    'packageId': 0,
    'paymentType': 1,
    'payTypeDetail': '',
    'payTypeName': '',
    'receiptNo': '',
    'transType': 0,
    'amount': 0,
    'narration': '',
    'glLevel1': '',
    'glLevel2': '',
    'glAccount': '',
    'openBal': 0,
    'closeBal': 0,
    'serialNo': 0,
    'status': 1,
    'createdBy': 0,
    'updatedBy': 0
}

const foreignAddress = {
    'id': 0,
    'addressLine1': '',
    'addressLine2': '',
    'city': '',
    'state': '',
    'country': '',
    'countryCode': '',
    'zipCode': '',
    'tel': '',
    'createdBy': 0,
    'updatedBy': 0
}

const multimedia = {
    'id':0, 
    'mediaType':'', 
    'owner':'', 
    'ownerId':0, 
    'caption':'', 
    'sortOrder':0, 
    'filename':``,
    'createdBy': 0,
    'updatedBy': 0
}

const missions = {
    'id': 0,
    'shipmentCode': '',
    'branchId': 0,
    'missionType': 0,
    'missionDetail': '',
    'dueDate': new Date(),
    'completedDate': new Date(),
    'missionStatus': 0,
    'isArchived': false,
    'createdBy': 0,
    'updatedBy': 0,
}

const packageMissions = {
    'id': 0,
    'packageCode': '',
    'branchId': 0,
    'missionType': 1,
    'missionDetail': '',
    'dueDate': new Date(),
    'completedDate': new Date(),
    'missionStatus': 0,
    'isArchived': false,
    'createdBy': 0,
    'updatedBy': 0,
}

const warehouse = {
    'id': 0,
    'name': '',
    'location': '',
    'cityId': 0,
    'latitude':0.0,
    'longitude':0.0,
    'isArchived': false,
    'createdBy': 0,
    'updatedBy': 0,
}

const comments = {
    'id': 0,
    'comment': '',
    'schema': '',
    'schemaId': 0,
    'isArchived': false,
    'createdBy': 0,
    'updatedBy': 0,
}

const authorize = {
    'id': 0,
    'userId': 0,
    'authType': 0,
    'isArchived': false,
    'createdBy': 0,
    'updatedBy': 0,
}

const tickets = {
    'id':0,
    'title':'',
    'description':'',
    'ticketCategory':0,
    'ticketStatus':1,
    'ticketPriority':1,
    'ticketOwner':'',
    'assignedAgent':0,
    'createdBy':0,
    'name':''
}

Dto.login = login;
Dto.package = packages;
Dto.roles = roles;
Dto.users = users;
Dto.region = region;
Dto.country = country;
Dto.city = city;
Dto.areas = areas;
Dto.branch = branch;
Dto.dropdown = dropdown;
Dto.message = message;
Dto.placeholder = placeholder;
Dto.clients = clients;
Dto.currency = currency;
Dto.cost = cost;
Dto.address = address;
Dto.shipment = shipment;
Dto.shipmentPackage = shipmentPackage;

Dto.accountLevel1 = accountLevel1;
Dto.accountLevel2 = accountLevel2;
Dto.accounts = accounts;
Dto.glConfig = glConfig;
Dto.glBalances = glBalances;
Dto.clientBalance = clientBalance;
Dto.journals = journals;
Dto.foreignAddress = foreignAddress;

Dto.multimedia = multimedia;
Dto.missions = missions;
Dto.packageMissions = packageMissions;
Dto.warehouse = warehouse;
Dto.comments = comments;
Dto.authorize = authorize;
Dto.tickets = tickets;

export default Dto;

export {
    login, packages, roles, users, country, region, city, areas,
    branch, dropdown, message, placeholder, clients, currency,
    cost, address, shipment, shipmentPackage, accountLevel1,
    accountLevel2, accounts, glConfig, glBalances, clientBalance,
    journals, foreignAddress, multimedia, missions, warehouse,
    comments, authorize, packageMissions, tickets
}

