import React, { Suspense, useEffect, useState } from 'react';
import { Header, SideMenu } from './LayoutComponent';
import $ from 'jquery';
import { HashRouter, Route, Routes, BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from 'history';
import routes from '../../utils/routes';
import moment from 'moment'
import Home from '../dashboard/Home';
import { useTranslation } from 'react-i18next';
import { GetCookie, SetCookie } from '../../utils/MojaveLib';

function DefaultLayout(){

    const {t, i18n} = useTranslation('translation');

    useEffect(() => {
        $('body').removeAttr('style').addClass('light-skin');

    }, []);

    const history = createBrowserHistory({
        // basename: '/adinkra-cargo/'
        basename: '/'
    }) ;

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);

        SetCookie('acargo-language', lang);
    }

    return(
        <>
            <div className="wrapper">
                
            <Header changeLanguage={changeLanguage} />
            
            <BrowserRouter>
                <SideMenu />
            </BrowserRouter>

            <div className="content-wrapper" style={{minHeight:'83vh'}}>
                <div className="container-full">
                    <Suspense fallback={<div />}>
                        <HashRouter history={history}>
                            <Routes>
                                {routes.map((route, idx) => route.component ? (
                                    <Route exact key={Math.random()} path={route.path} name={route.name} element={
                                        <route.component key={Math.random()} />} />
                                    ) : (null)
                                )}
                                <Route exact path="/" element={<Home />} />
                            </Routes>
                        </HashRouter>
                    </Suspense>
                </div>
            </div>
            
            <footer className="main-footer">
                <div className="pull-right d-none d-sm-inline-block">
                    <ul className="nav nav-primary nav-dotted nav-dot-separated justify-content-center justify-content-md-end">
                    <li className="nav-item">
                        <a className="nav-link" href="javascript:void(0)">{t("menu.support")}</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">{t("menu.terms")}</a>
                    </li>
                    </ul>
                </div>
                &copy; {moment().format('YYYY')} <a href="#/">Adinkra Group</a>. {t("menu.copyright")}.
            </footer>

            <aside className="control-sidebar">
                
                <div className="rpanel-title"><span className="pull-right btn btn-circle btn-danger" data-toggle="control-sidebar"><i className="ion ion-close text-white"></i></span> </div>  
                <ul className="nav nav-tabs control-sidebar-tabs">
                <li className="nav-item"><a href="#control-sidebar-home-tab" data-bs-toggle="tab" className="active"><i className="mdi mdi-message-text"></i></a></li>
                <li className="nav-item"><a href="#control-sidebar-settings-tab" data-bs-toggle="tab"><i className="mdi mdi-playlist-check"></i></a></li>
                </ul>

                <div className="tab-content">
                <div className="tab-pane active" id="control-sidebar-home-tab">
                    <div className="flexbox">
                        <a href="javascript:void(0)" className="text-grey">
                            <i className="ti-more"></i>
                        </a>	
                        <p>Users</p>
                        <a href="javascript:void(0)" className="text-end text-grey"><i className="ti-plus"></i></a>
                    </div>
                    <div className="lookup lookup-sm lookup-right d-none d-lg-block">
                        <input type="text" name="s" placeholder="Search" className="w-p100" />
                    </div>
                    <div className="media-list media-list-hover mt-20">
                        <div className="media py-10 px-0">
                        <a className="avatar avatar-lg status-success" href="#">
                            <img src="assets/images/avatar/1.jpg" alt="..." />
                        </a>
                        <div className="media-body">
                            <p className="fs-16">
                            <a className="hover-primary" href="#"><strong>Tyler</strong></a>
                            </p>
                            <p>Praesent tristique diam...</p>
                            <span>Just now</span>
                        </div>
                        </div>

                        <div className="media py-10 px-0">
                        <a className="avatar avatar-lg status-danger" href="#">
                            <img src="assets/images/avatar/2.jpg" alt="..." />
                        </a>
                        <div className="media-body">
                            <p className="fs-16">
                            <a className="hover-primary" href="#"><strong>Luke</strong></a>
                            </p>
                            <p>Cras tempor diam ...</p>
                            <span>33 min ago</span>
                        </div>
                        </div>

                        <div className="media py-10 px-0">
                        <a className="avatar avatar-lg status-warning" href="#">
                            <img src="assets/images/avatar/3.jpg" alt="..." />
                        </a>
                        <div className="media-body">
                            <p className="fs-16">
                            <a className="hover-primary" href="#"><strong>Evan</strong></a>
                            </p>
                            <p>In posuere tortor vel...</p>
                            <span>42 min ago</span>
                        </div>
                        </div>

                        <div className="media py-10 px-0">
                        <a className="avatar avatar-lg status-primary" href="#">
                            <img src="assets/images/avatar/4.jpg" alt="..." />
                        </a>
                        <div className="media-body">
                            <p className="fs-16">
                            <a className="hover-primary" href="#"><strong>Evan</strong></a>
                            </p>
                            <p>In posuere tortor vel...</p>
                            <span>42 min ago</span>
                        </div>
                        </div>			
                        
                        <div className="media py-10 px-0">
                        <a className="avatar avatar-lg status-success" href="#">
                            <img src="assets/images/avatar/1.jpg" alt="..." />
                        </a>
                        <div className="media-body">
                            <p className="fs-16">
                            <a className="hover-primary" href="#"><strong>Tyler</strong></a>
                            </p>
                            <p>Praesent tristique diam...</p>
                            <span>Just now</span>
                        </div>
                        </div>

                        <div className="media py-10 px-0">
                        <a className="avatar avatar-lg status-danger" href="#">
                            <img src="assets/images/avatar/2.jpg" alt="..." />
                        </a>
                        <div className="media-body">
                            <p className="fs-16">
                            <a className="hover-primary" href="#"><strong>Luke</strong></a>
                            </p>
                            <p>Cras tempor diam ...</p>
                            <span>33 min ago</span>
                        </div>
                        </div>

                        <div className="media py-10 px-0">
                        <a className="avatar avatar-lg status-warning" href="#">
                            <img src="assets/images/avatar/3.jpg" alt="..." />
                        </a>
                        <div className="media-body">
                            <p className="fs-16">
                            <a className="hover-primary" href="#"><strong>Evan</strong></a>
                            </p>
                            <p>In posuere tortor vel...</p>
                            <span>42 min ago</span>
                        </div>
                        </div>

                        <div className="media py-10 px-0">
                        <a className="avatar avatar-lg status-primary" href="#">
                            <img src="assets/images/avatar/4.jpg" alt="..." />
                        </a>
                        <div className="media-body">
                            <p className="fs-16">
                            <a className="hover-primary" href="#"><strong>Evan</strong></a>
                            </p>
                            <p>In posuere tortor vel...</p>
                            <span>42 min ago</span>
                        </div>
                        </div>
                        
                    </div>

                </div>
                
                <div className="tab-pane" id="control-sidebar-settings-tab">
                    <div className="flexbox">
                        <a href="javascript:void(0)" className="text-grey">
                            <i className="ti-more"></i>
                        </a>	
                        <p>Todo List</p>
                        <a href="javascript:void(0)" className="text-end text-grey"><i className="ti-plus"></i></a>
                    </div>
                    <ul className="todo-list mt-20">
                        <li className="py-15 px-5 by-1">
                        <input type="checkbox" id="basic_checkbox_1" className="filled-in" />
                        <label for="basic_checkbox_1" className="mb-0 h-15"></label>
                        <span className="text-line">Nulla vitae purus</span>
                        <small className="badge bg-danger"><i className="fa fa-clock-o"></i> 2 mins</small>
                        <div className="tools">
                            <i className="fa fa-edit"></i>
                            <i className="fa fa-trash-o"></i>
                        </div>
                        </li>
                        
                    </ul>
                </div>
                </div>
            </aside>
            
            <div className="control-sidebar-bg"></div>
            
            </div>
            
            <div className="sticky-toolbar">	    
                <a href="#" data-bs-toggle="tooltip" data-bs-placement="left" title="Buy Now" className="waves-effect waves-light btn btn-success btn-flat mb-5 btn-sm" target="_blank">
                    <span className="icon-Money"><span className="path1"></span><span className="path2"></span></span>
                </a>
                <a href="#" data-bs-toggle="tooltip" data-bs-placement="left" title="Portfolio" className="waves-effect waves-light btn btn-danger btn-flat mb-5 btn-sm" target="_blank">
                    <span className="icon-Image"></span>
                </a>
                <a id="chat-popup" href="#" data-bs-toggle="tooltip" data-bs-placement="left" title="Live Chat" className="waves-effect waves-light btn btn-warning btn-flat btn-sm">
                    <span className="icon-Group-chat"><span className="path1"></span><span className="path2"></span></span>
                </a>
            </div>
                
            <div id="chat-box-body">
                <div id="chat-circle" className="waves-effect waves-circle btn btn-circle btn-lg btn-warning l-h-70">
                    <div id="chat-overlay"></div>
                    <span className="icon-Group-chat fs-30"><span className="path1"></span><span className="path2"></span></span>
                </div>

                <div className="chat-box">
                    <div className="chat-box-header p-15 d-flex justify-content-between align-items-center">
                        <div className="btn-group">
                        <button className="waves-effect waves-circle btn btn-circle btn-primary-light h-40 w-40 rounded-circle l-h-45" type="button" data-bs-toggle="dropdown">
                            <span className="icon-Add-user fs-22"><span className="path1"></span><span className="path2"></span></span>
                        </button>
                        <div className="dropdown-menu min-w-200">
                            <a className="dropdown-item fs-16" href="#">
                                <span className="icon-Color me-15"></span>
                                New Group</a>
                            <a className="dropdown-item fs-16" href="#">
                                <span className="icon-Clipboard me-15"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                Contacts</a>
                            <a className="dropdown-item fs-16" href="#">
                                <span className="icon-Group me-15"><span className="path1"></span><span className="path2"></span></span>
                                Groups</a>
                            <a className="dropdown-item fs-16" href="#">
                                <span className="icon-Active-call me-15"><span className="path1"></span><span className="path2"></span></span>
                                Calls</a>
                            <a className="dropdown-item fs-16" href="#">
                                <span className="icon-Settings1 me-15"><span className="path1"></span><span className="path2"></span></span>
                                Settings</a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item fs-16" href="#">
                                <span className="icon-Question-circle me-15"><span className="path1"></span><span className="path2"></span></span>
                                Help</a>
                            <a className="dropdown-item fs-16" href="#">
                                <span className="icon-Notifications me-15"><span className="path1"></span><span className="path2"></span></span> 
                                Privacy</a>
                        </div>
                        </div>
                        <div className="text-center flex-grow-1">
                            <div className="text-dark fs-18">Mayra Sibley</div>
                            <div>
                                <span className="badge badge-sm badge-dot badge-primary"></span>
                                <span className="text-muted fs-12">Active</span>
                            </div>
                        </div>
                        <div className="chat-box-toggle">
                            <button id="chat-box-toggle" className="waves-effect waves-circle btn btn-circle btn-danger-light h-40 w-40 rounded-circle l-h-45" type="button">
                            <span className="icon-Close fs-22"><span className="path1"></span><span className="path2"></span></span>
                            </button>                    
                        </div>
                    </div>
                    <div className="chat-box-body">
                        <div className="chat-box-overlay">   
                        </div>
                        <div className="chat-logs">
                            <div className="chat-msg user">
                                <div className="d-flex align-items-center">
                                    <span className="msg-avatar">
                                        <img src="assets/images/avatar/2.jpg" className="avatar avatar-lg" alt="" />
                                    </span>
                                    <div className="mx-10">
                                        <a href="#" className="text-dark hover-primary fw-bold">Mayra Sibley</a>
                                        <p className="text-muted fs-12 mb-0">2 Hours</p>
                                    </div>
                                </div>
                                <div className="cm-msg-text">
                                    Hi there, I'm Jesse and you?
                                </div>
                            </div>
                            <div className="chat-msg self">
                                <div className="d-flex align-items-center justify-content-end">
                                    <div className="mx-10">
                                        <a href="#" className="text-dark hover-primary fw-bold">You</a>
                                        <p className="text-muted fs-12 mb-0">3 minutes</p>
                                    </div>
                                    <span className="msg-avatar">
                                        <img src="assets/images/avatar/3.jpg" className="avatar avatar-lg" alt="" />
                                    </span>
                                </div>
                                <div className="cm-msg-text">
                                My name is Anne Clarc.         
                                </div>        
                            </div>
                            <div className="chat-msg user">
                                <div className="d-flex align-items-center">
                                    <span className="msg-avatar">
                                        <img src="assets/images/avatar/2.jpg" className="avatar avatar-lg" alt="" />
                                    </span>
                                    <div className="mx-10">
                                        <a href="#" className="text-dark hover-primary fw-bold">Mayra Sibley</a>
                                        <p className="text-muted fs-12 mb-0">40 seconds</p>
                                    </div>
                                </div>
                                <div className="cm-msg-text">
                                    Nice to meet you Anne.<br />How can i help you?
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="chat-input">      
                        <form>
                            <input type="text" id="chat-input" placeholder="Send a message..."/>
                            <button type="submit" className="chat-submit" id="chat-submit">
                                <span className="icon-Send fs-22"></span>
                            </button>
                        </form>      
                    </div>
                </div>
            </div>
        </>
    );
}

export default DefaultLayout;
