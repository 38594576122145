import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

function Home(){

    const {t, i18n} = useTranslation('translation');
    
    useEffect(() => {

    },[]);

    return(
        <section className="content">
            <div className="row">
                <div className="col-12">
                    <div className="box">
                        <div className="box-header">
                            <h4 className="box-title">{t("home.homePage")} </h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Home;
