import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import logo from '../../utils/assets/images/logo.png';
import Register from './Register';
import UserPass from './UserPass';
import { useTranslation } from "react-i18next";
import $ from 'jquery';
import Dto from '../../utils/serializers';
import { DoLogin, EncryptData, GetCookie } from '../../utils/MojaveLib';


function Login({changeLanguage}){

    const {t, i18n} = useTranslation('translation');

    const [loginItems, setLoginItems] = useState(Dto.login);

    useEffect(() => {
        $('body').attr('style', 'background-color:#1171C6');

    }, []);

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        setLoginItems(prevProps => ({...prevProps, [name]:value}));
    }

    const doLogin = (e) => {
        e.preventDefault();

        let formData = {...loginItems};
            formData.email = loginItems.email.trim();

        DoLogin(formData);
    }

    const showRegister = () => ReactDOM.render(<Register changeLanguage={changeLanguage} />, document.getElementById('loginMainCon'));

    const showUserPass = () => ReactDOM.render(<UserPass changeLanguage={changeLanguage} />, document.getElementById('loginMainCon'));

    return(
        <div className="row" style={{backgroundImage:'url(assets/images/login_bg.jpeg)', backgroundRepeat:'no-repeat', backgroundSize:'100% auto'}}>	
            <div className="col-12">
                <div className="row justify-content-end g-0">
                    <div className="col-xl-3 col-lg-4 col-md-5 col-12 " style={{height: '100vh'}}>
                        <div className="bg-white shadow-lg" style={{height: '100%', position:'relative'}}>
                            {/* <div className='align-items-center'> */}
                            <div className="content-top-agile p-20 mb-4 align-self-center">
                                <img src={logo} alt='Adinkra logo' style={{width: '200px'}} className='mb-4' />
                                <select className="form-select form-select-sm" name="" id=""
                                    value={GetCookie('acargo-language')} onChange={(e) => changeLanguage(e.target.value)} 
                                    style={{height: '32px', width: '80px', margin:'20px auto'}}>
                                    <option value={'en'}>{t("menu.langen")} </option>
                                    <option value={'zh'}>{t("menu.langzh")} </option>
                                </select>
                                <p className="mb-0">{t("login.notice")}</p>							
                            </div>

                            <div className="p-40" style={{bottom:'30px', position:'absolute'}}>
                                <form action="#" method="post" onSubmit={doLogin}>
                                    <div className="form-group">
                                        <div className="input-group mb-3">
                                            <span className="input-group-text bg-transparent"><i className="ti-user"></i></span>
                                            <input type="email" className="form-control ps-15 bg-transparent" placeholder={t("login.email")}
                                                value={loginItems.email} onChange={handleChange} name='email' />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group mb-3">
                                            <span className="input-group-text  bg-transparent"><i className="ti-lock"></i></span>
                                            <input type="password" className="form-control ps-15 bg-transparent" placeholder={t("login.password")}
                                                value={loginItems.password} onChange={handleChange} name='password' />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                        <div className="checkbox">
                                            <input type="checkbox" id="rememberMe" name='rememberMe' checked={loginItems.rememberMe}
                                             value={true} onChange={handleChange} />
                                            <label for="basic_checkbox_1">{t("login.remember")}</label>
                                        </div>
                                        </div>
                                        
                                        <div className="col-6">
                                        <div className="fog-pwd text-end">
                                            <a href="javascript:void(0)" onClick={showUserPass} className="hover-warning">
                                                <i className="ion ion-locked"></i> {t("login.forgot")}
                                            </a><br />
                                        </div>
                                        </div>
                                        
                                        <div className="col-12 text-center">
                                        <button type="submit" className="btn btn-danger mt-10">{t("login.btnLog")}</button>
                                        </div>
                                    </div>
                                </form>	
                                <div className="text-center">
                                    <p className="mt-15 mb-0"><a href="#" onClick={showRegister} className="text-warning ms-5">{t("login.register")}</a></p>
                                </div>	
                            </div>		
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
