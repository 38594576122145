import React, { useState, useEffect } from "react";
import logo from '../../logo.svg';
import moment from 'moment';
import $ from 'jquery';
import { Link, useLocation } from 'react-router-dom'
import { GetCookie, GetCurl, GetFeatures, GetJCookie, Logout, SetCookie } from "../../utils/MojaveLib";
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import * as Icon from 'react-feather';
import Menu from "./Menu";

const Logo = () => <div className="d-flex align-items-center logo-box justify-content-start">	
        <a href="/#" className="logo">
        {/* <div className="logo-mini w-50">
            <span className="light-logo"><img src="assets/images/logo-letter.png" alt="logo" /></span>
            <span className="dark-logo"><img src="assets/images/logo-letter.png" alt="logo" /></span>
        </div> */}
        <div className="logo-lg justify-content-center">
            <span className="light-logo"><img src="assets/images/logo-light-text.png" alt="logo" /></span>
            <span className="dark-logo"><img src="assets/images/logo-light-text.png" alt="logo" /></span>
        </div>
        </a>	
    </div>;

const Navbar = ({changeLanguage}) => {

    const {t, i18n} = useTranslation('translation');

    return <nav className="navbar navbar-static-top">
        <div className="app-menu">
            <ul className="header-megamenu nav">
                <li className="btn-group nav-item">
                    <a href="#" className="waves-effect waves-light nav-link push-btn btn-primary-light" data-toggle="push-menu" role="button">
                        <i data-feather="align-left"></i>
                    </a>
                </li>
            </ul> 
        </div>
            
        <div className="navbar-custom-menu r-side">
            <ul className="nav navbar-nav">	
            <li className="btn-group nav-item d-lg-inline-flex d-none">
                <select className="form-select form-select-sm align-self-center" name="" id=""
                    value={GetCookie('acargo-language')} onChange={(e) => changeLanguage(e.target.value)} 
                    style={{height: '32px'}}>
                    <option value={'en'}>{t("menu.langen")} </option>
                    <option value={'zh'}>{t("menu.langzh")} </option>
                </select>
            </li> 
            <li className="btn-group nav-item d-lg-inline-flex d-none">
                <a href="#" data-provide="fullscreen" className="waves-effect waves-light nav-link full-screen btn-warning-light" title={t("menu.fullscreen")}>
                    <i data-feather="maximize"></i>
                </a>
            </li> 
                
            <li className="dropdown notifications-menu">
                <a href="#" className="waves-effect waves-light dropdown-toggle btn-info-light" data-bs-toggle="dropdown" title={t("menu.notify")}>
                <i data-feather="bell"></i>
                </a>
                <ul className="dropdown-menu animated bounceIn">

                <li className="header">
                    <div className="p-20">
                        <div className="flexbox">
                            <div>
                                <h4 className="mb-0 mt-0">{t("menu.notify")}</h4>
                            </div>
                            <div>
                                <a href="#" className="text-danger">{t("menu.clear")}</a>
                            </div>
                        </div>
                    </div>
                </li>

                <li>
                    <ul className="menu sm-scrol">
                        <li>
                            <a href="#">
                            <i className="fa fa-users text-info"></i> Curabitur id eros quis nunc suscipit blandit.
                            </a>
                        </li>
                    
                    </ul>
                </li>
                <li className="footer">
                    <a href="#">{t("menu.view_all")}</a>
                </li>
                </ul>
            </li>	
            
            <li className="dropdown user user-menu">
                <a href="#" className="waves-effect waves-light dropdown-toggle w-auto l-h-12 bg-transparent py-0 no-shadow" data-bs-toggle="dropdown" title="User">
                    <img src="assets/images/avatar/avatar-1.png" className="avatar rounded-10 bg-primary-light h-40 w-40" alt="" />
                </a>
                <ul className="dropdown-menu animated flipInX">
                <li className="user-body">
                    <a className="dropdown-item" href="#/setting/myProfile">
                        <i className="ti-user text-muted me-2"></i> {t("menu.profile")}
                    </a>
                    {/* <a className="dropdown-item" href="#">
                        <i className="ti-settings text-muted me-2"></i> {t("menu.email")}
                    </a> */}
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="#/" onClick={Logout}>
                        <i className="ti-lock text-muted me-2"></i> {t("menu.logout")}
                    </a>
                </li>
                </ul>
            </li>
            </ul> 
        </div>
    </nav>;
}

const Footer = () => {

    const {t, i18n} = useTranslation('translation');

    return <div className="sidebar-widgets">
                <div className="copyright text-center m-25 text-white-50">
                    <p><strong className="d-block">Adinkra Group</strong> © {moment().format('YYYY')} {t('menu.copyright')}</p>
                </div>
            </div>;
}

const Header = ({changeLanguage}) => <header className="main-header">
        <Logo />
        <Navbar changeLanguage={changeLanguage} /> 
    </header>;

const SideMenu = () => {
    const {t, i18n} = useTranslation('translation');

    let murl = GetCurl(useLocation(),1),
        curl = GetCurl(useLocation(),2),
        features = GetFeatures(),
        role = GetJCookie('acargo-role');

    const menuClick = (e, itm) => {
        // e.preventDefault();

        if(!$(`#menu_${itm}`).hasClass('menu-open')){
            $(`[id^='menu_']`).removeClass('menu-open');
            $(`ul.sidebar-menu ul`).slideUp();

            $(`#menu_${itm}`).addClass('menu-open');
            $(`#menu_${itm} ul`).slideDown('slow');
        }
    }

    return <aside className="main-sidebar" style={{height:'100vh'}}>
            <section className="sidebar position-relative">
                <div className="multinav">
                    <div className="multinav-scroll">
                        <ul className="sidebar-menu" data-widget="tree" style={{height:'75vh'}}>
                            {Menu.map(menu => ((features !== undefined && 
                            features[`${menu.code}`] !== undefined && 5 && menu.code != 2) || 
                            (role.roleLevel == 5 && menu.code == 2)) &&
                            <li id={`menu_${menu.code}`} className={`treeview ${((murl === menu.link) || (menu.code == 1 && murl == '')) ?'menu-open' :''}`} 
                            onClick={(e) => menuClick(e, menu.code)}>
                                <a href={(curl ===  undefined)? `#`:`#/${murl}/${curl}`}>
                                    <i data-feather={menu.icon}></i>
                                    {/* <Icon.Camera /> */}
                                    <span>{t(`${menu.name}`)}</span>
                                    <span className="pull-right-container">
                                    <i className="fa fa-angle-right pull-right"></i>
                                    </span>
                                </a>
                                {menu.submenu.length > 0 &&
                                <ul className="treeview-menu" style={{display:`${((murl === menu.link) || (menu.code == 1 && murl == '')) ? 'block':'none'}`}}>
                                    {menu.submenu.map(smenu => features[`${menu.code}`].includes(smenu.code) &&
                                    <li>
                                        <a href={smenu.link} >
                                            <i className="icon-Commit">
                                                <span className="path1"></span><span className="path2"></span>
                                            </i> {t(`${smenu.name}`)}
                                        </a>
                                    </li>)}	
                                </ul>}
                            </li>)}
                        </ul>
                        
                        <Footer />
                    </div>
                </div>
            </section>
        </aside>
}

const LayoutComponent = () => {};

LayoutComponent.Header = Header;
LayoutComponent.SideMenu = SideMenu;

export default LayoutComponent;

export {
    Header, SideMenu
}