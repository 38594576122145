import React from 'react';
import { useLocation } from 'react-router-dom';

const Menu = [
    {code:'1', name:"menu.dashboard", link:'dashboard', icon:'monitor',
    submenu:[
        {code:1, link:'#/dashboard/informational', name: 'menu.dashinfo'},
        {code:2, link:'#/dashboard/analytic', name: 'menu.dashana'},
        {code:3, link:'#/dashboard/strategic', name: 'menu.dashstra'},
    ]},
    {code:'2', name:'menu.clients', link:'client', icon:'users',
    submenu:[
        {code:1, link:'#/client/shipment', name: 'menu.shipment'},
        {code:2, link:'#/client/address', name: 'menu.address'},
        {code:3, link:'#/client/postalAddress', name: 'menu.postal'},
        {code:4, link:'#/client/profile', name: 'menu.profile'},
        {code:5, link:'#/client/transaction', name: 'menu.trans'},
    ]},
    {code:'3', name:'menu.operations', link:'operation', icon:'truck',
    submenu:[
        {code:1, link:'#/operation/shipment', name: 'menu.shipment'},
        {code:8, link:'#/operation/package', name: 'menu.package'},
        {code:5, link:'#/operation/customer', name: 'menu.clients'},
        {code:2, link:'#/operation/mission', name: 'menu.mission'},
        {code:3, link:'#/operation/manifest', name: 'menu.manifest'},
        {code:6, link:'#/operation/customerService', name: 'Customer Service'},
        {code:4, link:'#/operation/transaction', name: 'menu.trans'},
        {code:7, link:'#/operation/supplierPayment', name: 'Supplier Payment'},
    ]},
    {code:'4', name:'menu.reports', link:'report', icon:'pie-chart',
    submenu:[
        {code:1, link:'#/report/shipment', name: 'menu.ship_report'},
        {code:2, link:'#/report/mission', name: 'menu.mission_report'},
        {code:3, link:'#/report/transaction', name: 'menu.trans_report'},
        {code:4, link:'#/report/branch', name: 'menu.branch_report'},
        {code:5, link:'#/report/customer', name: 'menu.customer_report'},
        {code:6, link:'#/report/driver', name: 'menu.driver_report'},
    ]},
    {code:'5', name:'menu.settings', link:'setting', icon:'sliders',
    submenu:[
        {code:1, link:'#/setting/general', name: 'menu.general'},
        // {code:2, link:'#/setting/package', name: 'Packages'},
        {code:3, link:'#/setting/access_control', name: 'menu.access_control'},
        {code:4, link:'#/setting/location', name: 'menu.location'},
        {code:5, link:'#/setting/currency', name: 'menu.currency'},
        {code:6, link:'#/setting/rate', name: 'menu.ship_rate'},
    ]},
]

export default Menu;
